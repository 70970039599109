<script setup lang="ts">
    import { useNuxtApp } from 'nuxt/app';

    const appStore = useAppStore();
    const authStore = useAuthStore();
    const cartStore = useCartStore();
    const nuxtApp = useNuxtApp();
    const { locale, isoLocale } = useLocales();

    await appStore.checkWebsite(locale);

    const { notifications } = storeToRefs(appStore);

    onMounted(() => {
        cartStore.fetch({ locale: locale.value, isoLocale: isoLocale.value, $gtm: nuxtApp.$gtm, authStore });
    });
</script>

<template>
    <div class="absolute left-0 top-0 z-lg">
        <a
            class="relative inline-flex h-12 -translate-y-16 items-center justify-center rounded-md bg-woom-red px-4 text-base font-bold text-woom-white transition-transform focus:translate-y-0"
            href="#main-content">
            <span>{{ $t('cta.skipLink') }}</span>
        </a>
    </div>
    <client-only>
        <lazy-org-side-drawer />
        <!-- TODO: can we have one modal that rules them all here as well? -->
        <lazy-mol-video-modal />
        <lazy-org-global-dialog />
        <lazy-mol-account-dialog />
    </client-only>
    <mol-notification
        v-if="notifications?.[0]"
        :link="notifications[0].data?.link"
        :bg-color="notifications[0].data?.bg_color"
        :color="notifications[0].data?.text_color"
        :text="notifications[0].data.text"
        :storage="notifications[0].refs.links" />
    <org-header />

    <main
        id="main-content"
        class="min-h-screen-mobile lg:min-h-screen-desktop">
        <nuxt-page />
    </main>
    <lazy-mol-footer />
    <mol-toast-container />
</template>
