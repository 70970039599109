<script setup lang="ts">
    import type { CallToAction, MenuItem } from '~/graphql/generated';

    declare interface HeaderDesktopNavProps {
        menu: {
            items: MenuItem[];
            cta?: CallToAction | null;
            ctavisibility?: string | null;
        };
        submenuId: string;
        handleMenuKeypress: ({ isOpen, event, itemId }: { isOpen: boolean; event: KeyboardEvent; itemId: string }) => void;
        handleMouseover?: (menuItemId: string) => void;
        handleDetails: (menuItem: MenuItem) => void;
        activeMenuId?: string;
        showBorder?: boolean;
    }
    const props = defineProps<HeaderDesktopNavProps>();

    const menuListProps = computed(() => {
        return props.menu.items.some((item) => item.items?.length)
            ? {
                  role: 'menubar',
                  'aria-orientation': 'vertical',
              }
            : ({} as Record<string, any>);
    });
</script>
<template>
    <nav :class="['col-span-4 border-woom-grey-mid xl:col-span-3', { 'border-r': showBorder }]">
        <ul
            :id="`${submenuId}-submenu`"
            class="flex list-none flex-col items-start gap-4"
            v-bind="menuListProps">
            <li v-for="item in menu.items">
                <atm-desktop-menu-item
                    v-if="item"
                    :class="[
                        {
                            'text-2xl': item.type === 'featured',
                            'text-woom-red': item.items?.length && activeMenuId === item.id,
                        },
                    ]"
                    :handle-menu-keypress="({ isOpen, event }) => handleMenuKeypress({ isOpen, event, itemId: item.id })"
                    :item="item"
                    v-bind="getMenuItemProps(item)"
                    @mouseover="() => handleMouseover?.(item.id)"
                    @details="handleDetails" />
            </li>
            <li
                v-if="menu.cta && (!menu.ctavisibility || menu.ctavisibility !== 'mobile')"
                class="self-stretch pr-6">
                <lazy-atm-button
                    v-bind="ctaToButton(menu.cta)"
                    kind="link"
                    block
                    @keydown.esc.tab="handleMenuKeypress({ event: $event, isOpen: false, itemId: 'cta' })">
                    <template
                        #icon
                        v-if="menu.cta.icon">
                        <component
                            :is="menu.cta.icon.data.icon"
                            class="h-6 w-6" />
                    </template>

                    <renderer-html :content="woomTextFormat(menu.cta.link?.label ?? $t('cta.moreInfo'))" />
                </lazy-atm-button>
            </li>
        </ul>
        <slot></slot>
    </nav>
</template>
