<script setup lang="ts">
    interface ContactButtonProps {
        theme?: 'hamburger';
    }
    defineProps<ContactButtonProps>();

    const { contactUrl } = useURL();
</script>
<template>
    <nuxt-link
        :to="contactUrl"
        :class="[
            'atm-contact-button text-base transition-colors [&[href]]:hover:text-woom-red',
            {
                'flex items-center gap-3 border-b border-woom-grey-mid py-3 ': theme === 'hamburger',
            },
        ]">
        <woom-icon-headphones
            v-if="theme === 'hamburger'"
            class="h-6 w-6 fill-current" />
        <span :class="{ 'text-woom-grey-moon': theme === 'hamburger' }">{{ $t('contact') }}</span>
    </nuxt-link>
</template>
