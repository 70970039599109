<script setup lang="ts">
    interface NewsletterButtonProps {
        showIcon?: boolean;
    }
    defineProps<NewsletterButtonProps>();

    const { te, t } = useI18n();
    const localePath = useLocalePathWoom();
    const lookupKey = 'page.newsletterUrl';
    const newsletterLink = computed(() => te(lookupKey) && t(lookupKey));
</script>
<template>
    <nuxt-link
        v-if="newsletterLink"
        :to="localePath(newsletterLink)"
        class="atm-newsletter-button flex items-center gap-3 border-b border-woom-grey-mid py-3 text-base transition-colors hover:text-woom-red">
        <woom-icon-newsletter
            v-if="showIcon"
            class="h-6 w-6 fill-current" />
        <span :class="{ 'opacity-60': showIcon }">{{ $t('a11y.newsletterSignup') }}</span>
    </nuxt-link>
</template>
