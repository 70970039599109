export const useOnNavigation = (callback: () => void) => {
    const nuxtApp = useNuxtApp();

    onMounted(() => {
        nuxtApp.$eventEmitter.on('on-navigation', callback);
    });

    onUnmounted(() => {
        nuxtApp.$eventEmitter.off('on-navigation', callback);
    });
};
