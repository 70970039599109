<script setup lang="ts">
    import { useToast } from 'primevue/usetoast';

    const toast = useToast();
    const { previewEnabed } = usePreview();

    onMounted(() => {
        watch(
            previewEnabed,
            async (newValue) => {
                if (!newValue) return;
                await wait(1500);
                toast.add({
                    severity: 'info',
                    summary: 'Woom is currently in preview mode.',
                    life: 3000,
                    group: 'headless',
                });
            },
            {
                immediate: true,
            },
        );
    });
</script>

<template>
    <PrimeToast
        position="bottom-left"
        group="headless"
        unstyled>
        <template #container="{ message, closeCallback }">
            <div class="flex items-center gap-2 py-3 pl-4 pr-2 drop-shadow-md md:px-3 md:pl-6">
                <span>
                    {{ message.summary }}
                </span>
                <button
                    @click="closeCallback"
                    class="ml-auto h-12 w-12 shrink-0">
                    <woom-icon-close class="mx-auto h-4 w-4 fill-current" />
                </button>
            </div>
        </template>
    </PrimeToast>
</template>

<style>
    .p-toast-message-enter-active,
    .p-toast-message-leave-active {
        @apply transition duration-300 ease-out;
    }
    .p-toast-message-leave-active {
        @apply duration-150;
    }
    .p-toast-message-enter-from {
        @apply translate-y-12 opacity-0;
    }
    .p-toast-message-enter-to,
    .p-toast-message-leave-from {
        @apply translate-y-0 opacity-100;
    }
    .p-toast-message-leave-to {
        @apply -translate-y-12 opacity-0;
    }
</style>
