<script setup lang="ts">
    const { iso2Country } = useLocales();

    declare interface NotificationProps {
        text: string;
        bgColor?: string;
        color?: string;
        link?: Link;
        storage?: any;
    }
    const props = defineProps<NotificationProps>();

    const textColor = computed(() => {
        const color = props.color;
        if (color) {
            const colorHexValue = `#${color.replace('#', '')}`;
            return `color: ${colorHexValue}`;
        }
        return '';
    });

    const bgColor = computed(() => {
        const color = props.bgColor;
        if (color) {
            const colorHexValue = `#${color.replace('#', '')}`;
            return `background: ${colorHexValue}`;
        }
        return '';
    });
</script>
<template>
    <atm-link
        v-if="iso2Country.toLowerCase() !== 'us'"
        :link="link"
        :style="bgColor"
        link-only
        :storage="storage"
        class="mol-notification relative z-md block w-full bg-woom-black">
        <atm-grid>
            <div class="col-span-full flex h-12 items-center justify-center py-4 text-center lg:py-6">
                <span
                    class="line-clamp-2 font-mono text-xs text-white"
                    :style="textColor">
                    <renderer-html :content="woomTextFormat(text)" />
                </span>
            </div>
        </atm-grid>
    </atm-link>
</template>
