<script setup lang="ts">
    import type { CallToAction, MenuItem } from '~/graphql/generated';

    declare interface HeaderMobileNavProps {
        menu: {
            items: MenuItem[] | undefined | null;
            cta?: CallToAction | null;
            ctavisibility?: string | null;
        };
        menuLevel: number;
    }

    const props = defineProps<HeaderMobileNavProps>();

    const emit = defineEmits<{
        (e: 'open', data: MenuItem): void;
    }>();

    const openLevel = (item: MenuItem) => {
        emit('open', item);
    };

    const islevel3 = computed(() => {
        return props.menuLevel === 3;
    });
</script>
<template>
    <nuxt-link
        v-for="item in menu.items"
        :key="item.id"
        :class="[
            'mol-header-mobile-nav flex items-center justify-between border-b border-woom-grey-mid py-3 transition-colors hover:text-woom-red focus:text-woom-red',
            item.type === 'featured' ? ' text-lg' : ' text-base',
        ]"
        @click="openLevel(item)"
        v-bind="getMenuItemProps(item)">
        <span>
            <span :class="{ 'font-bold': item.type === 'featured' }">
                <renderer-html :content="woomTextFormat(item.title, islevel3)" />
            </span>

            <span
                v-if="item.subtitle"
                class="block font-book opacity-60">
                <renderer-html :content="woomTextFormat(item?.subtitle)" />
            </span>
        </span>
        <lazy-atm-image
            v-if="item.image"
            :src="item.image"
            :modifiers="{ f: 'webp' }"
            sizes="300"
            alt=""
            class="max-w-[150px]" />
        <lazy-woom-icon-chevron-right
            v-if="!!item.items?.length && !item.image"
            class="h-6 w-6 fill-current" />
    </nuxt-link>
    <slot></slot>
    <lazy-atm-button
        v-if="menu.cta && (!menu.ctavisibility || menu.ctavisibility !== 'desktop')"
        class="my-6"
        v-bind="ctaToButton(menu.cta)"
        kind="link">
        <template
            #icon
            v-if="menu.cta.icon">
            <component
                :is="menu.cta.icon.data.icon"
                class="h-6 w-6" />
        </template>

        <renderer-html :content="woomTextFormat(menu.cta.link?.label ?? $t('cta.moreInfo'))" />
    </lazy-atm-button>
</template>
